<template>
  <el-input
    :value="viewedValue"
    @input="handlePriceChange($event)"
    @blur="handlePriceChange($event, 'blur')"
    viewModeSuffix="€"
    v-bind="$attrs"
  >
  </el-input>
</template>
ƒ
<script>
import { stringToNumber } from "src/utils/stringToNumber";

export default {
  name: "price-input",
  props: {
    value: [String, Number],
  },
  data() {
    return {
      viewedValue: "",
    };
  },
  mounted() {
    if (this.value) {
      const newValAsPrice = this.numberToString(this.value);
      this.viewedValue = newValAsPrice;
    }
  },
  methods: {
    handlePriceChange(input, blur) {
      this.$nextTick(() => {
        this.formatCurrency(input, blur);
        const value = typeof input === "string" ? input : input.target.value;
        const numberValue = stringToNumber(value);
        this.$emit("input", numberValue);
      });
    },
    formatNumber(n) {
      // format number 1000000 to 1,234,567
      return n.replace(/[^0-9\-]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatCurrency(inputVal, blur) {
      let value = typeof inputVal === "string" ? inputVal : inputVal.target.value;
      // get input value
      // don't validate empty input
      if (value === "") {
        this.viewedValue = "";
        return;
      }

      // check for decimal
      if (value.indexOf(",") >= 0) {
        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        const decimalPos = value.indexOf(",");

        // split number by decimal point
        let leftSide = value.substring(0, decimalPos);
        let rightSide = value.substring(decimalPos);

        // add commas to left side of number
        leftSide = this.formatNumber(leftSide);

        // validate right side
        rightSide = this.formatNumber(rightSide);

        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
          rightSide += "00";
        }

        // Limit decimal to only 2 digits
        rightSide = rightSide.substring(0, 2);

        // join number by .
        value = leftSide + "," + rightSide;
      } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        value = this.formatNumber(value);
        value = value;

        // final formatting
        if (blur === "blur") {
          value += ",00";
        }
      }

      // send updated string to input
      this.viewedValue = value;
    },
    numberToString(n) {
      if (n) {
        return Number(n).toLocaleString("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
      } else {
        return n;
      }
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (!this.viewedValue && !isNaN(newVal)) {
        const newValAsPrice = this.numberToString(newVal);
        if (newValAsPrice !== this.viewedValue) {
          this.viewedValue = newValAsPrice;
        }
      }
    },
  },
};
</script>

<style></style>
