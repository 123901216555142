<template>
  <div>
    <form>
      <span class="n-profile-title">{{ tabName }}</span>

      <div class="card-body">
        <template v-if="computedEditMode">
          <el-row :gutter="20">
            <el-col style="flex-shrink: 0; width: 140px">
              <!-- AVATAR -->
              <avatar-uploader
                class="n-grid-container-edit-avatar"
                :isEditMode="computedEditMode"
                :actionURI="actionURI"
                :avatar="employee.picture"
                v-on:avatar-changed="setAvatar"
                :name="'employee_' + employee.id + '_picture_0'"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <!-- VORNAME -->
              <profile-input
                class="n-grid-container-edit-firstname"
                rules="required"
                required
                name="Vorname"
                key="firstname"
                v-model="employee.firstName"
                :label="$t('src.components.employees.profile.vorname')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <!-- NACHNAME -->
              <profile-input
                class="n-grid-container-edit-lastname"
                rules="required"
                required
                name="Nachname"
                key="lastname"
                v-model="employee.lastName"
                :label="$t('src.components.employees.profile.nachname')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <!-- RUFNAME -->
              <profile-input
                v-model="employee.nickname"
                :label="$t('src.components.employees.profile.rufname')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <!-- ROLLE DROPDOWN -->
              <profile-select
                :title="$t('src.components.employees.profile.rolle')"
                required
                rules="required"
                :name="$t('src.components.employees.profile.rolle')"
                :multiple="false"
                :items="companyRoles"
                v-model="employee.companyRole"
                valueIdentifier="name"
                :label="$t('src.components.employees.profile.name')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <!-- POSITION DROPDOWN -->
              <profile-select
                :title="$t('src.components.employees.profile.position')"
                :items="options['position']"
                :multiple="false"
                v-model="employee.selectedPosition"
                valueIdentifier="_id"
                label="name"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :lg="5" :md="7">
              <!-- TEAM -->
              <profile-input v-model="employee.team" :label="$t('team')" :editMode="computedEditMode" />
            </el-col>
            <el-col style="width: 50px">
              <!-- COLOR PICKER -->
              <span class="n-profile-label-title">{{ $t("src.components.employees.profile.farbe") }}</span
              ><br />
              <el-color-picker
                size="medium"
                :disabled="!computedEditMode"
                v-model="employee.color"
                :predefine="predefinedColors"
              ></el-color-picker>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col style="width: 140px">
              <!-- AKTIV/INAKTIV -->
              <profile-switch
                :title="$t('src.components.employees.profile.aktiv')"
                :disabled="!computedEditMode"
                v-model="employee.active"
                :active-text="$t('src.components.employees.profile.ja')"
                :inactive-text="$t('src.components.employees.profile.nein')"
                colorizeActive
              />
            </el-col>
          </el-row>
        </template>

        <template v-else>
          <el-row :gutter="20">
            <!-- AVATAR -->
            <el-col :lg="6" :md="8" style="display: flex; align-items: flex-start">
              <div style="flex-shrink: 0; display: inline-block; width: 140px">
                <avatar-uploader
                  :isEditMode="computedEditMode"
                  :actionURI="actionURI"
                  :avatar="employee.picture"
                  v-on:avatar-changed="setAvatar"
                  :name="'employee_' + employee.id + '_picture_0'"
                />
              </div>
              <div class="todo">
                <div class="n-profile-name">{{ employee.firstName }} {{ employee.lastName }}</div>
                <div class="n-profile-name-light">{{ employee.nickname }}</div>

                <div style="display: flex">
                  <!-- AKTIV/INAKTIV -->
                  <div style="display: flex; align-items: center; width: 80px" class="n-profile-name-light">
                    <el-badge class="profile" :type="employee.active ? 'success' : 'danger'" is-dot />
                    {{ employee.active ? "Aktiv" : "Inaktiv" }}
                  </div>
                  <!-- COLOR PICKER -->
                  <div style="width: 60px; display: inline-block">
                    <span class="n-profile-label-title">{{ $t("src.components.employees.profile.farbe") }}</span
                    ><br />
                    <div
                      v-bind:style="{
                        border: '1px solid black',
                        width: '30px',
                        height: '30px',
                        backgroundColor: employee.color,
                      }"
                    />
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="6" :md="8">
              <!-- ROLLE DROPDOWN -->
              <profile-select
                required
                :title="$t('src.components.employees.profile.rolle')"
                :items="companyRoles"
                :multiple="false"
                v-model="employee.companyRole"
                valueIdentifier="name"
                :label="$t('src.components.employees.profile.name')"
                :name="$t('src.components.employees.profile.rolle')"
                rules="required"
                :editMode="computedEditMode"
              />
            </el-col>

            <el-col :lg="6" :md="8">
              <!-- POSITION DROPDOWN -->
              <profile-select
                :title="$t('src.components.employees.profile.position')"
                :items="options['position']"
                :multiple="false"
                v-model="employee.selectedPosition"
                valueIdentifier="_id"
                :label="$t('src.components.employees.profile.name')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :lg="5" :md="7">
              <!-- TEAM -->
              <profile-input v-model="employee.team" :label="$t('team')" :editMode="computedEditMode" />
            </el-col>
          </el-row>
        </template>

        <el-row style="margin-top: 20px" :gutter="20">
          <el-col :lg="6" :md="8">
            <profile-select
              :title="$t('src.components.employees.profile.arbeitsqualifikationen')"
              :items="options['qualification']"
              v-model="employee.workingqualificationIDList"
              valueIdentifier="_id"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-select
              :title="$t('src.components.employees.profile.zertifikate')"
              :items="options['certificate']"
              v-model="employee.certificateIDList"
              valueIdentifier="_id"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-select
              :title="$t('src.components.employees.profile.bescheinigungen')"
              :items="options['confirmation']"
              v-model="employee.confirmationIDList"
              valueIdentifier="_id"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-select
              :title="$t('src.components.employees.profile.fhrerscheine')"
              :items="options['licence']"
              v-model="employee.licenceIDList"
              valueIdentifier="_id"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :lg="8" :md="12">
            <span class="n-profile-label-title">{{ $t("src.components.employees.profile.projektrollen") }}</span>
            <br />
            <el-checkbox-group
              v-model="employee.projectRoles"
              :disabled="!computedEditMode"
              data-testid="Project-rollen"
            >
              <el-checkbox label="CONSTRUCTION_MANAGER">{{
                $t("src.components.employees.profile.bauleiter")
              }}</el-checkbox>
              <el-checkbox label="RESPONSIBLE_EMPLOYEE">{{
                $t("src.components.employees.profile.verantwortlicherMitarbeiter")
              }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>

        <hr />

        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.employees.profile.allgemein") }}
        </div>

        <el-row style="margin-bottom: 40px" :gutter="20">
          <el-col :lg="6" :md="12">
            <profile-select
              :title="$t('src.components.employees.profile.geschlecht')"
              :multiple="false"
              :items="sexList"
              v-model="employee.sex"
              valueIdentifier="name"
              :label="$t('src.components.employees.profile.name')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="12">
            <profile-select
              :title="$t('src.components.employees.profile.nationalitt')"
              :multiple="false"
              :items="countries"
              v-model="employee.nationality"
              valueIdentifier="value"
              label="name"
              :editMode="computedEditMode"
            />
          </el-col>
        </el-row>

        <hr />

        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.employees.profile.kontaktdaten") }}
        </div>

        <el-row style="margin-bottom: 40px" :gutter="20">
          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.phone"
              :label="$t('src.components.employees.profile.telefon')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.mobile"
              :label="$t('src.components.employees.profile.handy')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.fax"
              :label="$t('src.components.employees.profile.fax')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.email"
              name="Email"
              rules="required|email"
              type="email"
              required
              :label="$t('src.components.employees.profile.email')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.emergencyNumber"
              :label="$t('src.components.employees.profile.notfallnummer')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :lg="6" :md="8">
            <profile-input
              type="textarea"
              v-model="employee.hrOffice.emergencyNumberInfo"
              :label="$t('infoEmergencyNumber')"
              :editMode="computedEditMode"
              :autosize="{ minRows: 3 }"
            />
          </el-col>
        </el-row>

        <hr />

        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.employees.profile.adressdaten") }}
        </div>

        <el-row :gutter="20" style="margin-bottom: 20px">
          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.street"
              :label="$t('src.components.employees.profile.strae')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.no"
              :label="$t('src.components.employees.profile.hausnummer')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.zip"
              :label="$t('src.components.employees.profile.plz')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.city"
              :label="$t('src.components.employees.profile.stadt')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.hrOffice.addressAddition"
              :label="$t('src.components.employees.profile.adresszusatz')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :lg="6" :md="8">
            <profile-select
              :title="$t('src.components.employees.profile.land')"
              :multiple="false"
              :items="countries"
              v-model="employee.hrOffice.country"
              valueIdentifier="name"
              :label="$t('src.components.employees.profile.name')"
              :editMode="computedEditMode"
            />
          </el-col>
        </el-row>

        <hr />
        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.employees.profile.geschftsdaten") }}
        </div>

        <el-row :gutter="20" style="margin-bottom: 20px">
          <el-col :lg="6" :md="8">
            <profile-input
              v-model="employee.employeeNumber"
              :label="$t('src.components.employees.profile.personalnummer')"
              :editMode="computedEditMode"
            />
          </el-col>
        </el-row>
        <date-history
          :isEditMode="computedEditMode"
          :dateHistory="employee.dateHistory"
          :dateOfEntering="employee.dateOfEntering"
          :dateOfLeaving="employee.dateOfLeaving"
          resourceType="employee"
          :resourceId="employee.id"
          @setProfileData="setProfileData"
          @saveDateHistory="saveDateHistory"
        />
      </div>
      <!-- END OF CARD -->
    </form>
  </div>
</template>

<script>
import {
  Message,
  Switch,
  MessageBox,
  RadioGroup,
  RadioButton,
  ColorPicker,
  Checkbox,
  CheckboxGroup,
  Timeline,
  TimelineItem,
} from "element-ui";
import { Avatar, Collapse, CollapseItem } from "src/components/UIComponents";
import countryList from "src/country-list.json";
import { mapActions } from "vuex";
import DateHistory from "../Generic/DateHistory.vue";

export default {
  name: "employee-profile",
  components: {
    Message,
    MessageBox,
    Collapse,
    CollapseItem,
    DateHistory,
    [Checkbox.name]: Checkbox,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [CheckboxGroup.name]: CheckboxGroup,
    [Switch.name]: Switch,
    [Avatar.name]: Avatar,
    [RadioButton.name]: RadioButton,
    [RadioGroup.name]: RadioGroup,
    [ColorPicker.name]: ColorPicker,
  },
  props: {
    employee: { type: Object, default: {} },
    accessRights: String,
    tabName: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
    updateProfile: {
      type: Function,
      required: true
    }
  },
  mounted() {
    this.$root.$on("employeePositionsUpdated", (data) => {
      this.options.position = data;
    });
    //register for profile stettings update
    this.$root.$on("settingsChanged", (data) => {
      console.log("employee - settingschanged");
      if (data.modelType === "employee") {
        //operation = "deleted" may remove the assignement
        if (data.operation === "deleted") {
          switch (data.modelID) {
            case "qualification":
              this.deleteSettings(data.options, this.employee.workingqualificationIDList);
              break;
            case "certificate":
              this.deleteSettings(data.options, this.employee.certificateIDList);
              break;
            case "confirmation":
              this.deleteSettings(data.options, this.employee.confirmationIDList);
              break;
            case "licence":
              this.deleteSettings(data.options, this.employee.licenceIDList);
              break;
            default:
              console.error(`Unknown data.modelID "${data.modelID}"`);
              break;
          }
        }
        //operation = "loaded", "updated" and "created" just updates the options select
        this.options[data.modelID] = data.options;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("settingsChanged");
    this.$root.$off("employeePositionsUpdated");
  },
  watch: {
    employee: {
      handler: function (newVal, oldVal) {
        this.checkDirty(newVal);
      },
      deep: true,
    },
  },
  data() {
    return {
      foo: [],
      companyRoles: [{ name: "Bauleitung" }, { name: "Gewerblich" }, { name: "Verwaltung" }],
      sexList: [{ name: "männlich" }, { name: "weiblich" }, { name: "divers" }],
      predefinedColors: [
        "#581713",
        "#a94b43",
        "#fbd283",
        "#62733f",
        "#034f73",
        "#96e3ff",
        "#255385",
        "#09111e",
        "#181238",
        "#d9d6cd",
        "#3d3936",
        "#837e7a",
        "#8d8d95",
        "#b5c4d7",
        "#dcba9f",
        "#d2cdca",
        "#fee0a0",
        "#84a0b5",
        "#bddc79",
        "#0ed145",
      ],
      options: {
        position: [], // comes from GET api/employees/positions
        licence: [],
        confirmation: [],
        certificate: [],
        qualification: [],
      },
      actionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      countries: countryList,
    };
  },
  methods: {
    ...mapActions("dirtyFlag", { checkDirty: "checkDirty" }),
    setProfileData(data) {
      this.$emit("setProfileData", data);
    },
    saveDateHistory(data) {
      this.$emit("savePartialProfileData", data);
    },
    deleteSettings(options, assignedIDs) {
      //synchonize allready assigned ids
      if (assignedIDs) {
        let updateProfileRequired = false;

        for (let index = 0; index < assignedIDs.length; index++) {
          let test = options.filter((option) => option._id === assignedIDs[index]);

          if (test.length === 0) {
            assignedIDs.splice(index); // remove this ID
            index -= 1; //correct index of for loop
            updateProfileRequired = true;
          }
        }

        if (updateProfileRequired) {
          updateProfile(this.employee);          
        }
      }
    },
    setAvatar(avatarSettings) {
      this.employee.picture = avatarSettings;
    },   
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
};
</script>

<style lang="scss">
.profile .el-badge__content.is-dot {
  padding: 0px 0px 0px 0px; /* padding: top right bottom left */
}
.n-grid-container-view {
  display: grid;
  grid-template-columns: 0px 100px 200px 100px;
  grid-template-areas:
    "avatar avatar content content"
    "avatar avatar content content"
    "avatar avatar content content"
    "avatar avatar content content"
    "avatar avatar content content";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
  text-align: left;
}

.n-grid-container-view-avatar {
  grid-area: avatar;
}
.n-grid-container-view-content {
  grid-area: content;
}

.n-grid-container-edit {
  display: grid;
  grid-template-columns: 0px 100px 100px 100px;
  grid-template-areas:
    "avatar avatar firstname firstname lastname lastname"
    "avatar avatar state empty nickname nickname";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
}

.n-grid-container-edit > div {
  text-align: left;
}

.n-grid-container-edit-avatar {
  grid-area: avatar;
}
.n-grid-container-edit-firstname {
  grid-area: firstname;
}
.n-grid-container-edit-lastname {
  grid-area: lastname;
}
.n-grid-container-edit-state {
  margin-top: 20px;
  grid-area: state;
}
.n-grid-container-edit-nickname {
  grid-area: nickname;
}
</style>
