<template>
  <widget-tab
    :title="$t('src.components.dashboard.views.dashboard.widgets.financeReport.financeReport')"
    :hasFilters="true"
    bodyOverflow="hidden"
  >
    <template #filters>
      <el-form :inline="true">
        <el-form-item>
          <profile-select
            :editMode="true"
            :multiple="false"
            v-model="activeFilter"
            :title="$t('src.components.administration.user.profile.aktivieren')"
            style="width: 150px"
            :items="activeOptions"
          />
        </el-form-item>
        <el-form-item>
          <profile-input
            :editMode="true"
            clearable
            v-model="filterQuery"
            :label="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.filterNachName')"
          />
        </el-form-item>
        <el-form-item>
          <profile-input
            :editMode="true"
            clearable
            v-model="filterManager"
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.aktuellerBauleiter')"
            :placeholder="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.aktuellerBauleiter')"
          />
        </el-form-item>
        <el-form-item
          ><profile-switch
            v-model="filterAvailable"
            :title="$t('filterAvailable')"
            :active-text="$t('src.components.employees.profile.ja')"
            :inactive-text="$t('src.components.employees.profile.nein')"
          />
        </el-form-item>
        <el-form-item
          ><profile-switch
            v-model="summarizeProjectPhases"
            title="Report Gesamtprojekt"
            :active-text="$t('src.components.employees.profile.ja')"
            :inactive-text="$t('src.components.employees.profile.nein')"
          />
        </el-form-item>
      </el-form>
    </template>
    <!-- <div v-loading="loading" style="min-height: 300px"> -->
    <div v-loading="loading" style="height: 100%">
      <construction-phase-finance-report
        :data="data"
        v-if="summarizeProjectPhases && !loading"
        :onLoadMore="handleLoadMore"
        ref="sumFinanceReportTable"
        :chunkSize="CHUNK_SIZE"
      />
      <finance-report-table
        v-else-if="!loading"
        :data="data"
        ref="financeReportTable"
        :onLoadMore="handleLoadMore"
        :chunkSize="CHUNK_SIZE"
      />
    </div>
  </widget-tab>
</template>

<script>
import { Form, FormItem, Message } from "element-ui";
import WidgetTab from "./../Widgets/WidgetTab.vue";
import { get, debounce } from "lodash";
import { moment } from "src/config/moment";
import ConstructionPhaseFinanceReport from "./ConstructionPhaseFinanceReport.vue";
import FinanceReportTable from "./FinanceReportTable.vue";

export default {
  name: "finance-report-widget",
  components: {
    WidgetTab,
    ConstructionPhaseFinanceReport,
    FinanceReportTable,
    [Message.name]: Message,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: {
    accessRights: { type: String },
  },
  data() {
    return {
      activeOptions: [
        { label: "Aktiv", value: true },
        { label: "Inaktiv", value: false },
        { label: "Alle", value: null },
      ],
      today: moment().format("L"),
      activeFilter: true,
      filterAvailable: true,
      summarizeProjectPhases: false,
      filterQuery: "",
      filterManager: "",
      data: [],
      employees: [],
      filteredData: [],
      loading: false,
      CHUNK: 0,
      CHUNK_SIZE: 40,
    };
  },
  created() {
    this.debounceFetch = debounce(this.fetch, 400);
  },
  async mounted() {
    this.fetch();
  },
  methods: {
    getQueryParams() {
      return {
        name: this.filterQuery ? this.filterQuery : undefined,
        constructionManagerName: this.filterManager ? this.filterManager : undefined,
        active: this.activeFilter !== null ? this.activeFilter : undefined,
        offset: this.CHUNK,
        hideEmptyReports: this.filterAvailable,
        limit: this.CHUNK_SIZE,
      };
    },
    getUrl() {
      if (this.summarizeProjectPhases) {
        return "/api/project-fulfillment/report/construction-phases";
      } else {
        return "/api/project-fulfillment/report";
      }
    },
    async fetch() {
      try {
        this.loading = true;
        this.CHUNK = 0;
        const params = this.getQueryParams();
        let response;
        response = await this.axios.get(this.getUrl(), {
          params,
        });
        this.data = this.normalizeResponse(response.data);
      } catch (error) {
        Message.error(get(error, "response.data.message", error.message));
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async handleLoadMore() {
      console.log("handleLoadMore");
      try {
        this.CHUNK += this.CHUNK_SIZE;
        const params = this.getQueryParams();
        const response = await this.axios.get(this.getUrl(), {
          params,
        });
        const normalized = this.normalizeResponse(response.data);
        this.data = this.data.concat(normalized);
        return response.data.length;
      } catch (error) {
        throw error;
      }
    },
    normalizeResponse(data) {
      return data.map((project) => {
        let statusColor;
        if (project.progress === "progress_1") {
          statusColor = "var(--project-progress-1)";
        } else if (project.progress === "progress_2") {
          statusColor = "var(--project-progress-2)";
        } else if (project.progress === "progress_3") {
          statusColor = "var(--project-progress-3)";
        }
        return {
          ...project,
          statusColor,
        };
      });
    },
  },
  watch: {
    summarizeProjectPhases() {
      this.data = [];
      this.fetch();
    },
    filterManager() {
      this.debounceFetch();
    },
    activeFilter() {
      this.fetch();
    },
    filterQuery() {
      this.debounceFetch();
    },
    filterAvailable() {
      this.fetch();
    },
  },
};
</script>

<style lang="scss">
.multi-progress-bar {
  height: 15px;
  border: 1px solid #dbdde3;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  min-width: 300px;
  width: 100%;
}
.progress-complete {
  z-index: 1;
  background-color: #2063eb;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.progress-fulfilled {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  background-size: 30px 30px;
  &.green-solid {
    background: #23c03f;
  }
  &.green-gradient {
    background-image: linear-gradient(
      45deg,
      #23c03f 25%,
      transparent 25%,
      transparent 50%,
      #23c03f 50%,
      #23c03f 75%,
      transparent 75%
    );
  }
  &.blue-gradient {
    background-image: linear-gradient(
      45deg,
      #2063eb 25%,
      transparent 25%,
      transparent 50%,
      #2063eb 50%,
      #2063eb 75%,
      transparent 75%
    );
  }
  &.red {
    background-color: #f42020;
  }
}
.blue-green-gradient {
  background-image: repeating-linear-gradient(45deg, #2063eb, #2063eb 15px, #23c03f 15px, #23c03f 30px);
}
</style>
