<template>
  <el-table
    class="finance-report d-widget-table"
    :data="normalizedData"
    style="width: 100%"
    max-height="auto"
    :default-sort="{ prop: 'dateRange', order: 'descending' }"
  >
    <el-table-column
      :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.projektname')"
      min-width="200"
      prop="name"
      sortable
    >
      <template v-slot="scope">
        <project-color :color="scope.row.statusColor" />
        <el-row type="flex" align="middle">
          <el-col>
            <el-badge
              :type="scope.row.active ? 'success' : 'danger'"
              style="padding-top: 9px; padding-left: 6px"
              is-dot
            />
            <router-link :to="getLinkToProjectOpened(scope.row)" v-if="hasAccessToControlling">
              {{ scope.row.name }}
            </router-link>
            <span v-else>{{ scope.row.name }}</span>
          </el-col>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column prop="constructionPhase" label="Bauabschnitt" width="120" sortable></el-table-column>
    <el-table-column
      prop="currentManagerName"
      :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.aktuellerBauleiter')"
      width="200"
      sortable
    ></el-table-column>
    <el-table-column
      :label="$t('src.components.project.projectcontrolling.projectcontrolling.orderValue')"
      width="180"
      prop="orderValue"
    >
      <template v-slot="scope">
        {{ scope.row.orderValue ? `${numberToString(scope.row.orderValue)} €` : "N/A" }}
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.projektlaufzeit')"
      prop="dateRange"
      :sort-method="sortDateRange"
      width="200"
      :formatter="dateRangeFormatter"
      sortable
    >
    </el-table-column>
    <el-table-column
      prop="percent"
      :label="$t('src.components.project.projectcontrolling.projectcontrolling.percent')"
      width="140"
      sortable
      :formatter="percentFormatter"
    ></el-table-column>
    <el-table-column prop="turnOverPerManDay" :label="$t('turnOverPerManDay')" width="200">
      <template v-slot="scope">
        <project-color :color="getTurnOverPerManDayColor(scope.row)" />
        {{ getTurnOverPerManDay(scope.row) }}
      </template>
    </el-table-column>

    <!-- STATUS_COLUMN -->
    <el-table-column :label="$t('status')" min-width="320">
      <template v-slot="scope">
        <div>
          <div>
            <!-- UPPER_STATUS_BAR -->
            <div class="multi-progress-bar" style="margin-bottom: 3px">
              <!-- PLACEHOLDER -->
              <el-tooltip
                v-if="!scope.row.manDaysPlanned"
                :content="`0/${formatToComma(scope.row.manDaysTotal || 0)} Manntag(e)`"
              >
                <div class="progress-fulfilled" style="width: 100%; background-color: #dbdde3; z-index: 1000"></div>
              </el-tooltip>
              <!-- COMPLETE_BAR -->
              <el-tooltip v-else :content="getManDaysUsedTooltip(scope.row)">
                <div
                  :class="['progress-complete', { 'blue-green-gradient': isFutureProject(scope.row) }]"
                  :style="getManDaysUsedStyle(scope.row)"
                ></div>
              </el-tooltip>
              <!-- DELTA_BAR -->
              <el-tooltip :content="getManDaysPlannedTooltip(scope.row)">
                <div class="progress-fulfilled blue-gradient" :style="getManDaysPlannedStyle(scope.row)"></div>
              </el-tooltip>
              <!-- WHAT_IS_LEFT_BAR -->
              <el-tooltip :content="getManDaysLeftTooltip(scope.row)">
                <div :class="getManDaysLeftClass(scope.row)" :style="getManDaysLeftStyle(scope.row)"></div>
              </el-tooltip>
            </div>
            <!-- LOWER_PROGRESS_BAR -->
            <div class="multi-progress-bar" v-if="scope.row.percent">
              <el-tooltip :content="getLowerProgressTooltip(scope.row)">
                <div class="progress-complete" :style="{ width: `${scope.row.percent}%` }"></div>
              </el-tooltip>
              <el-tooltip :content="getPercentContent(scope.row)">
                <div :class="getPercentUsedClass(scope.row)" :style="getPercentUsedStyle(scope.row)"></div>
              </el-tooltip>
            </div>
          </div>
          <!-- <div v-else>N/A</div> -->
        </div>
      </template>
    </el-table-column>

    <infinite-loading
      slot="append"
      :identifier="infiniteId"
      v-if="onLoadMore"
      @infinite="infiniteHandler"
      force-use-infinite-wrapper=".finance-report .el-table__body-wrapper"
      :distance="100"
    >
      <!-- TODO: is this slot ok here -->
      <template v-slot:no-more>
        <span></span>
      </template>
    </infinite-loading>
  </el-table>
</template>

<script>
import ProjectColor from "../../../../Project/ProjectColor.vue";
import { numberToString } from "src/utils/numberToString";
import { moment } from "src/config/moment";
import { mapState } from "vuex";
import { Badge, Tooltip } from "element-ui";
import { get } from "lodash";
import InfiniteLoading from "vue-infinite-loading";
import { last } from "lodash";

export default {
  name: "finance-report-table",
  props: {
    data: { type: Array, default: () => [] },
    onLoadMore: { type: Function },
    chunkSize: { type: Number },
  },
  components: {
    InfiniteLoading,
    ProjectColor,
    [Badge.name]: Badge,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      today: moment().startOf("day"),
      todayString: moment().format("L"),
      infiniteId: 0,
    };
  },
  methods: {
    isFutureProject(record) {
      return moment(record.dateRange[0]).isAfter(this.today, "day");
    },
    getLowerProgressTooltip(record) {
      return `${record.percent}% des Projekts bis ${moment(record.percentDate).format("L")} erfüllt`;
    },
    processConstructionManagers(data) {
      const today = moment();
      return data.map((item) => {
        let currentManager;
        if (item.constructionManagers) {
          const managers = item.constructionManagers.filter((record) => record.position === "CONSTRUCTION_MANAGER");
          currentManager = managers.find((record) => today.within(moment.range(record.dateRange).snapTo("day")));
          if (!currentManager && managers.length) {
            currentManager = last(managers);
          }
        }
        if (currentManager) {
          item.currentManagerName = currentManager.employee;
        }
        if (typeof item.constructionPhase === "number") {
          item.constructionPhase = `BA ${item.constructionPhase}`;
        }
        return item;
      });
    },
    sortDateRange(a, b) {
      const aVal = a.dateRange;
      const bVal = b.dateRange;
      if (!aVal.length) {
        if (!bVal.length) {
          return 0;
        }
        return -1;
      }
      if (!bVal.length) {
        return 1;
      }
      const aDate = new Date(aVal[0]);
      const bDate = new Date(bVal[0]);
      if (aDate < bDate) {
        return -1;
      } else if (bDate < aDate) {
        return 1;
      } else {
        return 0;
      }
    },
    getPercentContent(record) {
      let content;
      const diff = (1 - record.percentUsed / record.percent) * 100;
      const manDaysDiffToTarget = this.formatToComma(
        Math.abs((record.manDaysTotal * record.percent) / 100 - record.manDaysSpentTillLastProgress)
      );
      const diffPercents = this.formatToComma(Math.abs(diff));

      if (diff < 0) {
        content = `Personalaufwand ${diffPercents}% / ${manDaysDiffToTarget} Manntag(e) über Budget`;
      } else {
        content = `Personalaufwand ${diffPercents}% / ${manDaysDiffToTarget} Manntag(e) unter Budget`;
      }
      return content;
    },
    getPercentUsedClass(record) {
      if (record.percent === record.percentUsed) {
        return "";
      }
      let className = "progress-fulfilled";
      if (parseInt(record.percent) < parseInt(record.percentUsed)) {
        className += " red";
      } else {
        className += " green-gradient";
      }
      return className;
    },
    getPercentUsedStyle(record) {
      const diff = record.percentUsed - record.percent;
      const absDiff = Math.abs(diff);
      // bad - highlight red zone that was underperformed
      if (diff > 0) {
        // if underperformance overlaps total budget - use ratio to fit available width
        const ratio = Math.min(1, 100 / record.percentUsed);
        return { left: `${record.percent * ratio}%`, width: `${absDiff * ratio}%` };
      } else {
        // good - highlight green zone that is over performance
        return { left: `${record.percent - absDiff}%`, width: `${absDiff}%` };
      }
    },
    getManDaysLeftClass(record) {
      let className = "progress-fulfilled";
      if (record.manDaysTotal >= record.manDaysPlanned) {
        className += " green-solid";
      } else {
        className += " red";
      }
      return className;
    },
    getManDaysPlannedTooltip(record) {
      if (record.manDaysSpentTillToday) {
        return `${record.manDaysPlanned}/${this.formatToComma(
          record.manDaysTotal || 0
        )} Manntag(e) verbraucht und verplant bis ${moment(record.lastPlannedEndDate).format("L")}`;
      } else {
        return `${record.manDaysPlanned}/${this.formatToComma(
          record.manDaysTotal || 0
        )} Manntag(e) verplant bis ${moment(record.lastPlannedEndDate).format("L")}`;
      }
    },
    getManDaysUsedTooltip(record) {
      return `${record.manDaysSpentTillToday}/${this.formatToComma(
        record.manDaysTotal || 0
      )} Manntag(e) verbraucht bis heute, ${this.todayString}`;
    },
    getManDaysWidth(manDaysTotal, manDaysSpent) {
      // if greater than total - use ratio
      if (manDaysTotal < manDaysSpent) {
        const diff = manDaysSpent - manDaysTotal;
        return { width: `${((manDaysSpent - diff) / manDaysSpent) * 100}%` };
      } else {
        return { width: `${(manDaysSpent / manDaysTotal) * 100}%` };
      }
    },
    getManDaysUsedStyle(record) {
      if (this.isFutureProject(record)) {
        return this.getManDaysWidth(record.manDaysTotal, record.manDaysSpentTillLastProgress);
      } else {
        return this.getManDaysWidth(record.manDaysTotal, record.manDaysSpentTillToday);
      }
    },
    getManDaysLeftStyle({ manDaysTotal, manDaysSpentTillToday }) {
      // if greater than total - use ratio
      const diff = Math.abs(manDaysTotal - manDaysSpentTillToday);
      const { width: leftPosition } = this.getManDaysWidth(manDaysTotal, manDaysSpentTillToday);
      if (diff > 0) {
        return { left: leftPosition, width: `${(diff / manDaysTotal) * 100}%` };
      } else {
        return { left: leftPosition, width: `${(diff / manDaysTotal) * 100}%` };
      }
    },
    getManDaysLeftTooltip(record) {
      const diff = record.manDaysTotal - record.manDaysPlanned;
      if (diff < 0) {
        return `${this.formatToComma(Math.abs(diff))} Manntag(e) über Budget`;
      } else {
        return `${this.formatToComma(diff)} Manntag(e) übrig`;
      }
    },
    formatToComma(number) {
      return Number(number).toLocaleString("de-DE", { maximumFractionDigits: 2 });
    },
    getManDaysPlannedStyle({ manDaysTotal, manDaysSpentTillToday, manDaysPlanned, name }) {
      // if greater than total - use ratio
      const { width: leftPosition } = this.getManDaysWidth(manDaysTotal, manDaysSpentTillToday);
      // planned - 2, total 1.5, spent - 0
      const diff = manDaysPlanned - manDaysTotal;
      const styles = {
        left: leftPosition,
        width: null,
        zIndex: 10,
      };
      if (diff > 0) {
        styles.width = `${(1 - diff / manDaysTotal) * 100}%`;
      } else {
        styles.width = `${((manDaysPlanned - manDaysSpentTillToday) / manDaysTotal) * 100}%`;
      }

      return styles;
    },
    getLinkToProjectOpened(record) {
      return {
        name: "Project View",
        params: { id: record._id, tab_pane: "projectControlling" },
      };
    },
    dateRangeFormatter(props) {
      const { dateRange } = props;
      if (dateRange && dateRange.length) {
        return `${moment(dateRange[0]).format("L")} - ${moment(dateRange[1]).format("L")}`;
      } else {
        return "";
      }
    },
    numberToString: numberToString,
    getTurnOverPerManDayColor(record) {
      if (!record.orderValue || !record.percent || !record.manDaysSpentTillToday) {
        return "";
      }

      const value = (record.orderValue * (record.percent / 100)) / record.manDaysSpentTillToday;
      if (value >= record.targetTurnOver) {
        return "#23c03f";
      } else {
        return "#f42020";
      }
    },
    getTurnOverPerManDay(record) {
      if (!record.orderValue || !record.percent || !record.manDaysSpentTillToday) {
        return "N/A";
      }
      const value = (record.orderValue * (record.percent / 100)) / record.manDaysSpentTillToday;
      return numberToString(value) + " €";
    },
    percentFormatter(record) {
      if (record.percent) {
        return `${record.percent} (${moment(record.percentDate).format("L")})`;
      }
    },
    async infiniteHandler($state) {
      try {
        if (!this.data.length) {
          $state.complete();
          return;
        }
        const responseLength = await this.onLoadMore();
        $state.loaded();
        if (responseLength < this.chunkSize) {
          $state.complete();
        }
      } catch (error) {
        $state.error();
        throw error;
      }
    },
    discardInfiniteScroll() {
      this.infiniteId++;
    },
  },
  computed: {
    ...mapState("account", { appAccessRights: "accessRights" }),
    normalizedData() {
      return this.processConstructionManagers(this.data || []);
    },
    hasAccessToControlling() {
      const projectAccess = !!get(this.appAccessRights, "project.generalAccess");
      if (!projectAccess) {
        return projectAccess;
      }
      return !!get(this.appAccessRights, "project.specificAccess.projectControlling");
    },
  },
};
</script>
