<template>
  <el-table
    class="sum-finance-report d-widget-table"
    :data="tableData"
    style="width: 100%"
    max-height="auto"
    :default-sort="{ prop: 'dateRange', order: 'descending' }"
  >
    <el-table-column type="expand">
      <template v-slot="props">
        <finance-report-table :data="props.row.childProjects" />
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.projektname')"
      min-width="200"
      prop="name"
      sortable
    >
      <template v-slot="scope">
        <project-color :color="scope.row.statusColor" />
        <el-row type="flex" align="middle">
          <el-col>
            {{ scope.row.name }}
          </el-col>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('src.components.project.projectcontrolling.projectcontrolling.orderValue')"
      width="180"
      prop="totalOrderValue"
    >
      <template v-slot="scope">
        <div>{{ scope.row.totalOrderValue | formatPrice }}</div>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.projektlaufzeit')"
      prop="totalDateRange"
      :sort-method="sortDateRange"
      width="200"
      :formatter="dateRangeFormatter"
      sortable
    >
    </el-table-column>
    <el-table-column
      :label="$t('src.components.project.projectcontrolling.projectcontrolling.percent')"
      prop="percent"
      :sort-method="sortDateRange"
      width="160"
    >
      <template v-slot="scope">
        {{ getPercentForConstrPhase(scope.row) }}
      </template> </el-table-column
    ><el-table-column prop="turnOverPerManDay" :label="$t('turnOverPerManDay')" width="200">
      <template v-slot="scope">
        <project-color :color="getTurnOverPerManDayColor(scope.row)" />
        {{ getTurnOverPerManDay(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column :label="$t('status')" min-width="320">
      <template v-slot="scope">
        <div>
          <!-- UPPER_STATUS_BAR -->
          <div class="multi-progress-bar" style="margin-bottom: 3px">
            <!-- PLACEHOLDER -->
            <el-tooltip
              v-if="!scope.row.manDaysPlanned"
              :content="`0/${formatToComma(scope.row.manDaysTotal || 0)} Manntag(e)`"
            >
              <div class="progress-fulfilled" style="width: 100%; background-color: #dbdde3"></div>
            </el-tooltip>
            <!-- COMPLETE_BAR -->
            <el-tooltip
              v-else
              :content="`${scope.row.manDaysSpentTillToday}/${formatToComma(
                scope.row.manDaysTotal || 0
              )} Manntag(e) verbraucht bis heute, ${today}`"
            >
              <div
                class="progress-complete"
                :style="getManDaysUsedStyle(scope.row.manDaysTotal, scope.row.manDaysSpentTillToday)"
              ></div>
            </el-tooltip>
            <!-- DELTA_BAR -->
            <el-tooltip :content="getManDaysPlannedTooltip(scope.row)">
              <div class="progress-fulfilled blue-gradient" :style="getManDaysPlannedStyle(scope.row)"></div>
            </el-tooltip>
            <!-- WHAT_IS_LEFT_BAR -->
            <el-tooltip :content="getManDaysLeftTooltip(scope.row)">
              <div :class="getManDaysLeftClass(scope.row)" :style="getManDaysLeftStyle(scope.row)"></div>
            </el-tooltip>
          </div>
          <!-- LOWER_PROGRESS_BAR -->
          <div class="multi-progress-bar" v-if="scope.row.percent">
            <el-tooltip :content="`${scope.row.percent}% des Projekts erfüllt`">
              <div class="progress-complete" :style="{ width: `${scope.row.percent}%` }"></div>
            </el-tooltip>
            <el-tooltip :content="getPercentContent(scope.row)">
              <div :class="getPercentUsedClass(scope.row)" :style="getPercentUsedStyle(scope.row)"></div>
            </el-tooltip>
          </div>
        </div>
      </template>
    </el-table-column>

    <infinite-loading
      slot="append"
      :identifier="infiniteId"
      @infinite="infiniteHandler"
      force-use-infinite-wrapper=".sum-finance-report .el-table__body-wrapper"
      :distance="100"
    >
      <!-- TODO: is this slot ok here? -->
      <template v-slot:no-more>
        <span></span>
      </template>
    </infinite-loading>
  </el-table>
</template>

<script>
import { Tooltip, Table, TableColumn, Badge, Message } from "element-ui";
import { moment } from "src/config/moment";
import ProjectColor from "../../../../Project/ProjectColor.vue";
import FinanceReportTable from "./FinanceReportTable.vue";
import { numberToString } from "src/utils/numberToString";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "construction-phase-finance-report",
  props: {
    data: { type: Array, default: () => [] },
    onLoadMore: { type: Function, required: true },
    chunkSize: { type: Number },
  },
  components: {
    InfiniteLoading,
    ProjectColor,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Badge.name]: Badge,
    FinanceReportTable,
  },
  data() {
    return {
      // normalized for expandable rows table data
      tableData: [],
      infiniteId: 0,
      today: moment().format("L"),
    };
  },
  mounted() {
    this.tableData = this.data.slice();
  },
  methods: {
    formatToComma(number) {
      return Number(number).toLocaleString("de-DE", { maximumFractionDigits: 2 });
    },
    dateRangeFormatter(props) {
      const { totalDateRange } = props;
      if (totalDateRange && totalDateRange.length) {
        return `${moment(totalDateRange[0]).format("L")} - ${moment(totalDateRange[1]).format("L")}`;
      } else {
        return "";
      }
    },
    sortDateRange(a, b) {
      const aVal = a.totalDateRange;
      const bVal = b.totalDateRange;
      if (!aVal.length) {
        if (!bVal.length) {
          return 0;
        }
        return -1;
      }
      if (!bVal.length) {
        return 1;
      }
      const aDate = new Date(aVal[0]);
      const bDate = new Date(bVal[0]);
      if (aDate < bDate) {
        return -1;
      } else if (bDate < aDate) {
        return 1;
      } else {
        return 0;
      }
    },
    getManDaysUsedStyle(manDaysTotal, manDaysSpent) {
      // if greater than total - use ratio
      if (manDaysTotal < manDaysSpent) {
        const diff = manDaysSpent - manDaysTotal;
        return { width: `${((manDaysSpent - diff) / manDaysSpent) * 100}%` };
      } else {
        return { width: `${(manDaysSpent / manDaysTotal) * 100}%` };
      }
    },
    getManDaysPlannedTooltip(record) {
      return `${record.manDaysPlanned}/${this.formatToComma(
        record.manDaysTotal || 0
      )} Manntag(e) verbraucht und verplant bis ${moment(record.lastPlannedEndDate).format("L")}`;
    },
    getManDaysPlannedStyle({
      manDaysTotal,
      manDaysSpentTillLastProgress,
      manDaysSpentTillToday,
      manDaysPlanned,
      name,
    }) {
      //TODO: filter by bauleiter - corrupts the report computations
      // if greater than total - use ratio
      const { width: leftPosition } = this.getManDaysUsedStyle(manDaysTotal, manDaysSpentTillToday);
      const diff = manDaysSpentTillToday - manDaysTotal;
      if (diff > 0) {
        return {
          left: leftPosition,
          // width: `${Math.min((diff / manDaysTotal) * 100, 100 - leftPosition.replace("%", ""))}%`,
          width: `${(1 - diff / manDaysTotal) * 100}%`,
          zIndex: 10,
        };
      } else {
        return {
          left: leftPosition,
          width: `${((manDaysPlanned - manDaysSpentTillToday) / manDaysTotal) * 100}%`,
          zIndex: 10,
        };
      }
    },
    getManDaysLeftTooltip(record) {
      const diff = record.manDaysTotal - record.manDaysPlanned;
      if (diff < 0) {
        return `${this.formatToComma(Math.abs(diff))} Manntag(e) über Budget`;
      } else {
        return `${this.formatToComma(diff)} Manntag(e) übrig`;
      }
    },
    getManDaysLeftClass(record) {
      let className = "progress-fulfilled";
      if (record.manDaysTotal >= record.manDaysPlanned) {
        className += " green-solid";
      } else {
        className += " red";
      }
      return className;
    },
    getManDaysLeftStyle({ manDaysTotal, manDaysSpentTillLastProgress, manDaysSpentTillToday }) {
      // if greater than total - use ratio
      const diff = Math.abs(manDaysTotal - manDaysSpentTillToday);
      const { width: leftPosition } = this.getManDaysUsedStyle(manDaysTotal, manDaysSpentTillToday);
      if (diff > 0) {
        return { left: leftPosition, width: `${(diff / manDaysTotal) * 100}%` };
      } else {
        return { left: leftPosition, width: `${(diff / manDaysTotal) * 100}%` };
      }
    },
    getTurnOverPerManDayColor(record) {
      if (!record.sumOrderValue || !record.percent || !record.manDaysSpentTillLastProgress) {
        return "";
      }
      // #1913 https://www.goodday.work/t/CQevoL/UBA00P
      const numerator = record.childProjects.reduce((result, project) => {
        result += project.manDaysSpentTillLastProgress * project.targetTurnOver;
        return result;
      }, 0);
      const denomerator = record.childProjects.reduce((result, project) => {
        result += project.manDaysSpentTillLastProgress;
        return result;
      }, 0);
      // const averageTargetTurnOver = numerator / denomerator;

      // const value = record.sumOrderValue / record.manDaysSpentTillLastProgress;
      const value = record.turnOverPerManDay;
      if (value >= record.targetTurnOver) {
        return "#23c03f";
      } else {
        return "#f42020";
      }
    },
    getTurnOverPerManDay(record) {
      // if (!record.sumOrderValue || !record.percent || !record.manDaysSpentTillLastProgress) {
      //   return "N/A";
      // }
      if (!record.turnOverPerManDay) {
        return "N/A";
      }
      // const value = (record.sumOrderValue * (record.percent / 100)) / record.manDaysSpentTillToday;
      return numberToString(record.turnOverPerManDay) + " €";
    },
    getPercentForConstrPhase(record) {
      if (record.percent === "NaN") {
        return "";
      }
      let text = `${record.percent}`;
      if (record.lastProgressDate) {
        text += ` (${moment(record.lastProgressDate).format("L")})`;
      }
      return text;
    },
    getPercentContent(record) {
      let content;
      const diff = (1 - record.percentUsed / record.percent) * 100;
      const manDaysDiffToTarget = this.formatToComma(
        Math.abs((record.manDaysTotal * record.percent) / 100 - record.manDaysSpentTillLastProgress)
      );
      if (diff < 0) {
        content = `Personalaufwand ${this.formatToComma(
          Math.abs(diff)
        )}% / ${manDaysDiffToTarget} Manntag(e) über Budget`;
      } else {
        content = `Personalaufwand ${this.formatToComma(
          Math.abs(diff)
        )}% / ${manDaysDiffToTarget} Manntag(e) unter Budget`;
      }
      return content;
    },
    getPercentUsedClass(record) {
      if (record.percent === record.percentUsed) {
        return "";
      }
      let className = "progress-fulfilled";
      if (parseInt(record.percent) < parseInt(record.percentUsed)) {
        className += " red";
      } else {
        className += " green-gradient";
      }
      return className;
    },
    getPercentUsedStyle(record) {
      const diff = record.percentUsed - record.percent;
      const absDiff = Math.abs(diff);
      // bad - highlight red zone that was underperformed
      if (diff > 0) {
        // if underperformance overlaps total budget - use ratio to fit available width
        const ratio = Math.min(1, 100 / record.percentUsed);
        return { left: `${record.percent * ratio}%`, width: `${absDiff * ratio}%` };
      } else {
        // good - highlight green zone that is over performance
        return { left: `${record.percent - absDiff}%`, width: `${absDiff}%` };
      }
    },
    async infiniteHandler($state) {
      try {
        if (!this.data.length) {
          $state.complete();
          return;
        }
        const responseLength = await this.onLoadMore();
        $state.loaded();
        if (responseLength < this.chunkSize) {
          $state.complete();
        }
      } catch (error) {
        $state.error();
        throw error;
      }
    },
    discardInfiniteScroll() {
      this.infiniteId++;
    },
  },
  filters: {
    formatPrice: function (price) {
      if (price) {
        return parseFloat(price).toLocaleString("de-DE") + " €";
      } else {
        return "-";
      }
    },
  },
  watch: {
    data(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.tableData = newVal;
      }
    },
  },
};
</script>

<style></style>
