<template>
  <div :class="avatarClassComputed">
    <el-upload
      :class="['avatar-upload', { small: small }]"
      :headers="authHeader"
      :action="actionURI"
      :data="{ metadata: name }"
      :disabled="!isEditMode"
      :file-list="filelist"
      v-bind:limit="1"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :multiple="false"
      :on-remove="handleRemove"
      :before-remove="handleBeforeRemove"
      :on-success="Uploaded"
      :on-change="handleChange"
      accept="image/jpeg,image/png,image/gif"
      :before-upload="validateFileSize"
      :on-error="handleUploadError"
      :auto-upload="autoUpload"
      ref="upload"
    >
      <pencil-icon v-if="isEditMode" />
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" style="text-align: center">
      <lazy-image v-if="dialogVisible" width="100%" :src="avatar.url" />
    </el-dialog>
  </div>
</template>

<script>
import { Upload, Dialog, Message } from "element-ui";
import { mapGetters } from "vuex";
import { isEmpty, get } from "lodash";
// import { LazyImage } from "src/components/UIComponents";
import LazyImage from "../Image/LazyImage.vue";

export default {
  name: "avatar-uploader",
  components: {
    LazyImage,
    // [LazyImage.name]: LazyImage,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Message.name]: Message,
  },
  props: {
    autoUpload: { type: Boolean, default: true },
    actionURI: String,
    name: String,
    avatar: Object,
    small: Boolean,
    isEditMode: Boolean,
  },
  mounted() {
    if (this.avatar && !isEmpty(this.avatar)) {
      this.setFileList(this.avatar);
    }
  },
  watch: {
    avatar: function (newVal, oldVal) {
      if (newVal && !isEmpty(newVal)) {
        const newPicture = { ...newVal };
        if (!this.filelist.length || this.filelist[0].url !== newPicture.url) {
          this.setFileList(newPicture);
        }
      }
    },
  },

  data() {
    return {
      filelist: [],
      dialogVisible: false,
      uploadDisabled: false,
      fileSizeError: false,
    };
  },
  computed: {
    ...mapGetters("account", ["authHeader"]),
    imageUrl() {
      return get(this.filelist, "[0].url", undefined);
    },
    avatarClassComputed() {
      let avatarClassComputed = "";
      if ((this.filelist && this.filelist.length) || this.uploadDisabled) {
        avatarClassComputed += "upload-disabled";
      }
      if (!this.isEditMode) {
        avatarClassComputed += " avatar-view";
      }
      return avatarClassComputed;
    },
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    async setFileList(data) {
      const imageResponse = await this.axios.get(data.url, { responseType: "blob" });
      const thumbnailResponse = await this.axios.get(data.thumbnail, { responseType: "blob" });
      const imageObjectUrl = URL.createObjectURL(imageResponse.data);
      const thumbnailObjectUrl = URL.createObjectURL(thumbnailResponse.data);
      this.filelist = [
        {
          name: data.name,
          url: imageObjectUrl,
          thumbnail: thumbnailObjectUrl,
        },
      ];
    },
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    Uploaded(response, file, files) {
      //emit function in base component
      const formattedResoponse = {
        name: response[0].name,
        url: response[0].url,
        thumbnail: response[0].thumbnail,
      };
      this.$emit("avatar-changed", formattedResoponse);
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },
    handleChange(file, fileList) {
      this.$emit("change", file, fileList);
      this.uploadDisabled = true;
    },
    handleRemove(file, files) {
      this.uploadDisabled = false;
      this.$emit("remove");
      if (this.name) {
        this.axios
          .delete("/api/avatars", {
            params: {
              filename: file.name,
              metadata: this.name,
            },
          })
          .then((response) => {
            this.dialogVisible = false;
            //emit function in base component
            this.$emit("avatar-changed", null);
            this.filelist = [];
          });
      }
    },
    handleBeforeRemove() {
      if (this.fileSizeError) {
        this.fileSizeError = false;
        return true;
      } else {
        return this.$confirmDelete("Wollen Sie das Bild wirklich löschen");
      }
    },
    validateFileSize(file) {
      const limit = 1.5 * 1000000; // 1.5MB
      if (file.size > limit) {
        Message.error("Die Bildgröße sollte weniger als 1,5MB betragen");
        this.fileSizeError = true;
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.avatar-upload .is-disabled + .el-upload--picture-card {
  border-style: solid;
  cursor: default;
  &:hover {
    border: 1px solid #c0ccda;
  }
}
.upload-disabled .el-upload.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card .el-upload-list__item {
  border: 0px solid #c0ccda !important;
  height: auto !important;
  border-radius: 0 !important;
}

.el-upload.el-upload--picture-card {
  width: 120px;
  height: 120px;
}

.el-upload--picture-card .material-design-icon.pencil-icon {
  bottom: 10%;
}

.avatar-upload .el-upload-list--picture-card .el-upload-list__item {
  width: 120px !important;
  height: 120px !important;
  transition: none !important;
}
.avatar-upload.small .el-upload-list--picture-card .el-upload-list__item {
  width: 60px !important;
  height: 60px !important;
}
.el-upload-list__item.is-success.el-upload-list__item-thumbnail {
  width: 120px !important;
  height: 120px !important;
}
.avatar-view {
  .el-upload-list__item-actions {
    display: none;
  }
  .el-upload-list__item-status-label {
    display: none;
  }
  .el-upload-list__item-status-label {
    display: none !important;
  }
}
.avatar-upload .el-upload-list__item .el-icon-close-tip {
  display: none !important;
}
.avatar-upload.small {
  .el-upload.el-upload--picture-card {
    line-height: 73px;
    height: 60px;
    width: 60px;
  }
}
.avatar-upload {
  .el-upload-list__item {
    display: flex !important;
    align-items: center;
  }
  .el-upload-list__item-status-label {
    display: none !important;
  }
  img {
    height: auto !important;
  }
}
</style>
