export function getGranularityMode(resourceType) {
  try {
    const json = localStorage.getItem("granularity_settings");
    if (json) {
      const storage = JSON.parse(json);
      return storage[resourceType] || "STANDARD";
    }
  } catch (error) {
    return "STANDARD";
  }
}
