<script>
import { Message } from "element-ui";
import { moment } from "src/config/moment";
import { groupBy, sortBy } from "lodash";
import { getGranularityMode } from "src/utils/getGranularityMode";

const singularToUrl = {
  employee: "employees",
  machine: "machines",
  rhb: "rhb",
  vehicle: "vehicles",
  supply: "supply",
  subcontractor: "subcontractor",
};

// helper functions
function generateRowsFromResources(resources, resourceEventColor) {
  return resources.map((resource) => ({
    id: resource.id,
    active: resource.active,
    name: resource.title,
    position: resource.position,
    companyRole: resource.companyRole,
    displayName: resource.displayName,
    resourceType: resource.resourceType,
    productGroup1: resource.productGroup1,
    productGroup2: resource.productGroup2,
    dateHistory: resource.dateHistory,
    eventColor: resourceEventColor,
    unitName: resource.unitName,
    team: resource.team,
    thumbnail: resource.thumbnail,
    picture: resource.picture,
    // imageUrl: "https://raw.githubusercontent.com/mockoon/mock-samples/main/mock-apis/logos/mercedes-benzcom-image.jpeg",
  }));
}

function generateEvents(events, allResources) {
  return events.map((event) => {
    let name = event.projectTitle || event.title;
    if (event.quantity) {
      const supply = allResources[event.resourceType].find(({ id }) => event.resourceId === id);
      const unitName = supply && supply.unitName;
      name += ` (${event.quantity}${unitName ? ` ${unitName}` : ""})`;
    }
    return {
      id: event.id,
      _id: event.id,
      dbEventId: event.id,
      // projectTitle is set if event has projectId property. Otherwise it's name of status event
      name: name,
      projectTitle: event.projectTitle,
      order: event.order,
      projectId: event.projectId,
      resourceType: event.resourceType,
      resourceId: event.resourceId,
      startDate: moment(event.start).startOf("day").toDate(),
      endDate: moment(event.end)
        .subtract(4, "hours") // temporary handle differences in timezones. todo - handle dates with datestring yyyy-mm-dd - unbind time
        .endOf("day")
        .toDate(),
      eventColor: event.background, // is set for status events
      iconCls: event.icon,
      statusEvent: event.statusType,
      draggable: true,
      comment: event.comment,
      quantity: event.quantity,
      freeText: event.freeText,
      fileList: event.fileList,
      hasFiles: event.fileList && event.fileList.length,
      readOnlyAccess: event.readOnlyAccess,
      noFilesAccess: event.noFilesAccess,
      noFreeTextAccess: event.noFreeTextAccess,
      // draggable: !event.status, // dont allow to drag and drop status events (maybe temporary)
    };
  });
}

export default {
  name: "DataManager",
  components: {
    Message,
  },
  data() {
    return {
      viewDateRange: {
        startDate: moment().startOf("week").toDate(),
        endDate: moment().endOf("week").toDate(),
      },
      events: {
        employee: [],
        machine: [],
        rhb: [],
        vehicle: [],
        supply: [],
        subcontractor: [],
      },
      resources: {
        employee: [],
        machine: [],
        rhb: [],
        vehicle: [],
        supply: [],
        subcontractor: [],
      },
      accessRights: {},
      employeePositions: [],
      projects: {}, // object {[key: projectId]: Project} to optimize getting project record
      projectsSelection: [],
      productGroups: [],
    };
  },
  beforeDestroy() {
    window.filterUpperCalendarResourcesDays = undefined;
  },
  methods: {
    /**
     * Fetches all resources within Promise.all call to save time for initializing
     */
    async fetchAllResources() {
      try {
        const resourceEntries = Object.entries(singularToUrl);
        // make request calls
        const allResourcesAxiosCalls = resourceEntries.map((entry) => this.axios.get(`/api/${entry[1]}/calendar`));
        // await fuflillment and set data per each resource
        const result = await Promise.all(allResourcesAxiosCalls);
        resourceEntries.forEach((entry, idx) => {
          this.resources[entry[0]] = result[idx].data;
        });
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async fetchCalendarData(resourceType, eventColor, skipFetchResources) {
      const { startDate, endDate } = this.viewDateRange;
      const resources = await this.fetchResources(resourceType, skipFetchResources, eventColor);
      await this.fetchEvents(resourceType, startDate, endDate);
      return resources;
    },
    // skipFetchResources - stands for disabling fetch of resources. We don't need to fetch them another time right after initialize
    async fetchEmployees(skipFetchResources = false, eventColor) {
      const { startDate, endDate } = this.viewDateRange;
      const resources = await this.fetchResources("employee", skipFetchResources, eventColor);
      await this.fetchEvents("employee", startDate, endDate);
      return resources;
    },
    async fetchMachines(eventColor) {
      const { startDate, endDate } = this.viewDateRange;
      const resources = await this.fetchResources("machine", false, eventColor);
      await this.fetchEvents("machine", startDate, endDate);
      return resources;
    },
    async fetchVehicles(eventColor) {
      const { startDate, endDate } = this.viewDateRange;
      const resources = await this.fetchResources("vehicle", false, eventColor);
      await this.fetchEvents("vehicle", startDate, endDate);
      return resources;
    },
    async fetchRHB(eventColor) {
      const { startDate, endDate } = this.viewDateRange;
      const resources = await this.fetchResources("rhb", false, eventColor);
      await this.fetchEvents("rhb", startDate, endDate);
      return resources;
    },
    async fetchSupply(eventColor) {
      const { startDate, endDate } = this.viewDateRange;
      const resources = await this.fetchResources("supply", false, eventColor);
      await this.fetchEvents("supply", startDate, endDate);
      return resources;
    },
    async fetchSubcontractor(eventColor) {
      const { startDate, endDate } = this.viewDateRange;
      const resources = await this.fetchResources("subcontractor", false, eventColor);
      await this.fetchEvents("subcontractor", startDate, endDate);
      return resources;
    },
    /**
     * Fetches projects. Sets up project info and project selection list.
     */
    async fetchProjects() {
      try {
        const { data: projects } = await this.axios.get("/api/projects/calendar");
        const { data: bookings } = await this.axios.get("/api/project-hotel-bookings");
        // convert into map-like object to get project info easily
        this.projects = projects.reduce((obj, project) => {
          obj[project.id] = project;
          obj[project.id].children = project.children.filter(({ resourceType }) => !!this.accessRights[resourceType]);
          obj[project.id].bookings = bookings[project.id] || [];
          return obj;
        }, {});
        this.projectsSelection = projects.map((item) => ({
          text: item.title,
          dateRange: item.dateRange,
          value: item.id,
          active: item.active,
          isWorkshop: item.isWorkshop,
        }));
      } catch (error) {
        throw error;
      }
    },
    getBookingRecord(projectId, bookingId) {
      const project = this.projects[projectId];
      const booking = project.bookings.find((item) => item._id === bookingId);
      return booking;
    },
    reorderProjects(reorderedData = []) {
      reorderedData.forEach((item) => {
        const record = this.projects[item.id];
        if (record) {
          record.order = item.order;
        }
      });
    },
    /**
     * Fetches resources by given resourceType
     * @param {ResourceType} resourceType
     */
    async fetchResources(resourceType, skipFetchResources, resourceEventColor) {
      try {
        this.loading = true;
        if (!skipFetchResources) {
          const url = `/api/${singularToUrl[resourceType]}/calendar`;
          const response = await this.axios.get(url);
          this.resources[resourceType] = response.data;
        }
        if (resourceType === "employee") {
          await this.fetchPositions();
        }
        const generatedRows = generateRowsFromResources(this.resources[resourceType].slice(), resourceEventColor);
        return generatedRows;
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      } finally {
        this.loading = false;
      }
    },
    /**
     * Fetches events within date range for all resources
     * @param {ResourceType} resourceType
     * @param {Date} start
     * @param {Date} end
     */
    async fetchEvents(resourceType, start, end, silent = false) {
      try {
        this.loading = true;
        const { data: events } = await this.axios.get("/api/project-events", {
          params: { start: start.toJSON(), end: end.toJSON() },
        });
        this.axios.get("/api/project-events/mobile/projects", {
          params: { start: start.toJSON(), end: end.toJSON() },
        });
        this.axios.get("/api/project-events/mobile/resources", {
          params: { start: start.toJSON(), end: end.toJSON(), resourceType: "supply" },
        });
        Object.keys(this.events).forEach((resourceTypeItem) => {
          // add projectTitle to display in events of resource calendar
          const normalizedEvents = events[resourceTypeItem].map((event) => {
            const project = this.projects[event.projectId] || {};
            return {
              ...event,
              end: moment(event.end).endOf("day").toDate(),
              projectTitle: event.projectId ? project.title || `Project ${event.projectId}` : null,
              order: project.order,
            };
          });
          this.events[resourceTypeItem] = normalizedEvents;
          // transform db event objects to Scheduler-supported format
        });

        if (window.resourceScheduler && !window.resourceScheduler.isDestroyed) {
          if (!silent) {
            const generatedEvents = generateEvents(this.events[resourceType].slice(), this.resources);
            // clear used here because otherwise the newly created events would appear overlapped
            // even thought they should not because if you refresh the page - the events don't overlap (https://www.goodday.work/t/rxV0cr)
            window.resourceScheduler.eventStore.clear();
            window.resourceScheduler.eventStore.add(generatedEvents);
          }
          // triggers busy days filter again
          this.updateBusyDayFilter();
        }
        // if (window.projectScheduler) {
        //   console.log("projectEvents", projectEvents);
        //   window.projectScheduler.eventStore.add(projectEvents);
        // }
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      } finally {
        this.loading = false;
      }
    },
    /**
     * Fetches employee positions and replaces with Position object for records that have position set up
     */
    async fetchPositions() {
      try {
        const { data: positions } = await this.axios.get("/api/employees/positions");
        this.employeePositions = positions;
        this.resources.employee = this.resources.employee.map((employee) => {
          if (employee.position) {
            const foundPosition = positions.find((positionItem) => positionItem._id === employee.position);
            return {
              ...employee,
              position: foundPosition ? foundPosition.name : null,
            };
          } else {
            return employee;
          }
        });
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    /**
     * Maps through all resource events (status and project) to return list of project events with changed "id"
     * to bind events to resources for each project
     */
    gatherAllProjectEvents() {
      const allProjectEvents = Object.keys(this.events).reduce((buffer, key) => {
        // leave only project events (filter out status events) and format event resourceId to bind it to resource of particular project
        const projectEvents = this.events[key].reduce((events, event) => {
          if (event.projectId) {
            let name = event.title;
            if (event.quantity) {
              const supply = this.resources.supply.find(({ id }) => event.resourceId === id);
              const unitName = supply && supply.unitName;
              name += ` (${event.quantity}${unitName ? ` ${unitName}` : ""})`;
            }
            events.push({
              id: event.id,
              name: name,
              resourceId: `${event.projectId}_${event.resourceType}`,
              startDate: moment(event.start).startOf("day").toDate(),
              endDate: moment(event.end)
                .subtract(4, "hour") // temporary handle differences in timezones. todo - handle dates with datestring yyyy-mm-dd - unbind time
                .endOf("day")
                .toDate(),
              resourceType: event.resourceType,
              dbEventId: event.id,
              dbResourceId: event.resourceId,
              projectId: event.projectId,
              comment: event.comment,
              quantity: event.quantity,
            });
          }
          return events;
        }, []);
        return buffer.concat(projectEvents);
      }, []);
      return allProjectEvents;
    },
    setDateRange({ startDate, endDate }) {
      this.viewDateRange = { startDate, endDate };
    },
    getProjectById(projectId) {
      return this.projects[projectId];
    },
    getResourcesByResourceType(resourceType) {
      return this.resources[resourceType];
    },
    // posts events and returns array of created events
    async addProjectEvents(events) {
      try {
        const self = this;
        const createdEvents = [];
        const promises = events.map((formBody) =>
          self.axios
            .post("/api/project-events", formBody, {
              params: { mode: getGranularityMode(formBody.resourceType) },
            })
            .then((response) => {
              self.events[response.data.resourceType].push(response.data);
              return response.data;
            })
            .then((event) => createdEvents.push(event))
            .catch((err) => {
              if (err.status === 403) {
                if (err.response.data.code === 101) {
                  Message({
                    message: "Ressource kann einem Projekt nicht doppelt zugewiesen werden",
                    type: "warning",
                  });
                } else if (err.response.data.code === 1) {
                  Message({
                    type: "error",
                    message: err.response.data.message,
                  });
                } else {
                  const resourceTypeToGerman = {
                    employee: "Mitarbeiter",
                    machine: "Maschine",
                    vehicle: "KFZ",
                    rhb: "RHB",
                  };
                  const resourceType = events[0].resourceType;
                  Message({
                    type: "error",
                    message: resourceTypeToGerman[resourceType] + " ist im angegebenen Zeitraum beschäftigt",
                  });
                }
              } else if (err.response.data.message) {
                Message.error(err.response.data.message);
              }
              throw err;
            })
        );
        await Promise.all(promises);
        if (window.resourceScheduler && !window.resourceScheduler.isDestroyed) {
          const newEventData = generateEvents(createdEvents, this.resources);
          const eventsWithTitleAsProjectName = newEventData.map(({ name, ...item }) => {
            const projectName = this.projects[item.projectId].title;
            return {
              ...item,
              name: projectName || name,
            };
          });
          window.resourceScheduler.eventStore.add(eventsWithTitleAsProjectName);
          window.projectScheduler.eventStore.add(newEventData);
        }
      } catch (error) {
        throw error;
      }
    },
    filterUpperCalendarResources(days) {
      if (!days) {
        return;
      }
      window.filterUpperCalendarResourcesDays = days;
      //get range of current view
      const { startDate, endDate } = this.viewDateRange;
      const resources = window.resourceScheduler.resourceStore.data;
      //reduce range to Mo. - Fr.
      const currentRange = moment.range(startDate, moment(startDate).add(days - 1, "days")).snapTo("day");
      const resourceType = resources[0].resourceType;
      const events = this.events[resourceType];

      let unavailableResourcesWithinCurrentRange = {};

      const resourcesWithEvents = groupBy(events, "resourceId");
      const currentRangeDays = Array.from(currentRange.by("day"));
      // console.log('currentRangeDays', currentRangeDays);
      Object.values(resourcesWithEvents).forEach((resourceEvents) => {
        let daysBusy = 0;
        for (let index = 0; index < currentRangeDays.length; index++) {
          const currentDay = currentRangeDays[index];
          if (
            resourceEvents.some((resourceEvent) =>
              moment
                .range(
                  moment(resourceEvent.start).startOf("day"),
                  moment(resourceEvent.end)
                    .subtract(4, "hours") // temporary handle differences in timezones. todo - handle dates with datestring yyyy-mm-dd - unbind time
                    .endOf("day")
                )
                .contains(currentDay)
            )
          ) {
            daysBusy++;
          }
        }
        if (currentRangeDays.length === daysBusy) {
          unavailableResourcesWithinCurrentRange[resourceEvents[0].resourceId] = true;
        }
      });
      // window.resourceScheduler.resourceStore.clearFilters();
      if (window.resourceScheduler.resourceStore.filters.includes((f) => f.id === "available_days_filter")) {
        store.removeFilter("available_days_filter");
      }
      const filterFn = (item) => !unavailableResourcesWithinCurrentRange[item.id];
      window.resourceScheduler.resourceStore.filter({
        id: `available_days_filter`,
        filterBy: filterFn,
      });
    },
    getDbEvent(resourceType, eventId) {
      if (!this.events[resourceType]) {
        throw new Error("Invalid resourceType: " + resourceType);
      }
      return this.events[resourceType].find((event) => event.id === eventId);
    },
    getUnavailableEventsPerResourceGroup(resourceGroup) {
      return this.events[resourceGroup].slice();
    },
    getUnavailableEventsPerResourceId(resourceType, resourceId) {
      let eventsByResource = this.getUnavailableEventsPerResourceGroup(resourceType);
      return eventsByResource.filter((item) => item.resourceId === resourceId);
    },
    async fetchProductGroups(resourceType) {
      try {
        const rootPg = await this.axios.get("/api/product-groups", { params: { modelType: resourceType + "_root" } });
        const response = await this.axios.get("/api/product-groups", { params: { modelType: resourceType } });
        this.productGroups = response.data.concat(rootPg.data);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    getProductGroups1() {
      const root = this.productGroups.find((item) => item.model.indexOf("_root") !== -1);
      const pgMap = groupBy(this.productGroups, "_id");
      if (!root || !root.children) {
        return [];
      }
      const pg1 = root.children.map((child) => {
        const record = pgMap[child];
        if (record && record[0]) {
          return {
            id: record[0]._id,
            text: record[0].label,
          };
        } else {
          return null;
        }
      });
      return pg1;
    },
    getProductGroups2(pg1Id) {
      const pg1 = this.productGroups.find((item) => item._id === pg1Id);
      const pgMap = groupBy(this.productGroups, "_id");
      if (!pg1 || !pg1.children) {
        return [];
      }
      const pg2 = pg1.children.map((child) => {
        const record = pgMap[child];
        if (record && record[0]) {
          return {
            id: record[0]._id,
            text: record[0].label,
          };
        } else {
          return null;
        }
      });
      return pg2;
    },
    updateBusyDayFilter() {
      const busyDaysFilter = window.resourceScheduler.resourceStore.filters.find(
        (f) => f.id === "available_days_filter"
      );
      if (busyDaysFilter) {
        // "days" parameter is stored under "property" key of CollectionFilter model
        window.resourceScheduler.trigger("filter_busy_resources", { days: window.filterUpperCalendarResourcesDays });
      }
    },
    getHotelsForDay({ day, projectId }) {
      const project = this.projects[projectId];
      if (!project) {
        console.error("Project not found:", projectId);
        return {};
      }
      const records = project.selectedHotels.filter(({ hotelDateRange }) =>
        moment(day).isBetween(hotelDateRange[0], hotelDateRange[1], "day", "[]")
      );
      return { projectName: project.title, records };
    },
    updateProjectHotelBooking(newBooking) {
      const project = this.projects[newBooking.projectId];
      const index = project.bookings.findIndex((item) => item._id === newBooking._id);
      if (index !== -1) {
        project.bookings[index] = { ...newBooking };
      }
    },
    createProjectHotelBookings(newBookings) {
      if (newBookings.length) {
        const project = this.projects[newBookings[0].projectId];
        project.bookings.push(...newBookings);
      }
    },
    deleteProjectHotelBooking(payload, deleteAllNext) {
      const project = this.projects[payload.projectId];
      if (deleteAllNext) {
        const sorted = sortBy(project.bookings, (o) => new Date(o.start).getTime());
        const index = sorted.findIndex((i) => i._id === payload._id);
        const result = sorted.slice(0, index);
        if (result.length) {
          project.bookings = result;
        } else {
          project.bookings = [];
        }
      } else {
        project.bookings = project.bookings.filter((item) => item._id !== payload._id);
      }
    },
    addEvent(event) {
      this.events[event.resourceType].push(event);
    },
    editEvent(event) {
      const idx = this.events[event.resourceType].findIndex((i) => event.id === i.id);
      if (idx !== -1) {
        this.events[event.resourceType][idx] = { ...event };
      } else {
        this.events[event.resourceType].push(event);
      }
    },
    async editComment(eventId, comment) {
      try {
        await this.axios.put(`/api/project-events/${eventId}`, { comment });
      } catch (error) {
        throw error;
      }
    },
    async editQuantity(eventId, quantity) {
      try {
        const response = await this.axios.put(`/api/project-events/${eventId}`, { quantity });
        const projectSchedulerRecord = window.projectScheduler.eventStore.findRecord("dbEventId", response.data.id);
        const resourceSchedulerRecord = window.resourceScheduler.eventStore.findRecord("dbEventId", response.data.id);
        if (projectSchedulerRecord) {
          projectSchedulerRecord.set({ quantity: response.data.quantity });
        }
        if (resourceSchedulerRecord) {
          resourceSchedulerRecord.set({ quantity: response.data.quantity });
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    setAccessRightsPerResourceType(accessRights) {
      console.log("accessRights", accessRights);
      this.accessRights = { ...accessRights };
    },
  },
  computed: {
    projectsList() {
      return Object.values(this.projects);
    },
  },
};
</script>
