<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }" slim>
    <div :class="['el-form-item', { 'is-error': !!errors[0] }]">
      <template v-if="editMode || showDisabledInput">
        <span v-if="!noLabel">
          <span v-if="required" style="" class="n-profile-select-title-red">*</span>
          <span class="n-profile-label-title">{{ label }}:</span>
          <br />
        </span>
        <div :class="['el-form-item__content', { 'd-flex': withCheckbox }]">
          <el-checkbox
            v-if="withCheckbox"
            :checked="showInput"
            @change="handleChangeFillable"
            class="mr-2"
            :border="true"
          >
            Fillable
          </el-checkbox>
          <el-input
            ref="inputRef"
            v-if="showInput"
            :size="size"
            :autosize="autosize"
            :type="type"
            :value="value"
            :placeholder="placeholder || '—'"
            :disabled="!editMode"
            :data-testid="name || label"
            v-bind="$attrs"
            @input="updateValue"
            @change="updateValue"
            @blur="$emit('blur', value)"
            :class="{ input: true, input__error: !!errors[0] }"
          >
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
              <slot :name="scopedSlotName" v-bind="slotData" />
            </template>
          </el-input>
          <transition name="slide">
            <div v-show="showErrorText && errors[0]" class="el-form-item__error">{{ customMessage || errors[0] }}</div>
          </transition>
        </div>
      </template>
      <template v-else>
        <template v-if="!noLabel">
          <span class="n-profile-label-title">{{ label }}:</span><br />
        </template>
        <span class="n-profile-label-value" :data-testid="name || label"
          >{{ value ? value : " — " }} {{ viewModeSuffix }}</span
        >
      </template>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { Checkbox } from "element-ui";

export default {
  name: "profile-input",
  components: {
    ValidationProvider,
    [Checkbox.name]: Checkbox,
  },
  props: {
    value: { type: [String, Number], default: null },
    customMessage: { type: String },
    name: { type: String },
    type: { type: String, default: "text" },
    rules: { type: String },
    placeholder: { type: String },
    showErrorText: { type: Boolean, default: true },
    required: { type: Boolean },
    editMode: { type: Boolean, default: false },
    showDisabledInput: { type: Boolean },
    noLabel: { type: Boolean },
    label: { type: String, default: null },
    viewModeSuffix: { type: String },
    size: { type: String },
    autosize: { type: [Boolean, Object], default: true },
    withCheckbox: { type: Boolean },
  },
  data() {
    return {
      editText: "Profil bearbeiten",
      showInput: !this.withCheckbox || !!this.value,
    };
  },
  methods: {
    updateValue(inputValue) {
      this.$emit("input", inputValue);
    },
    handleChangeFillable(checked) {
      this.showInput = checked;
      if (!checked) {
        this.updateValue("");
      }
    },
  },
  watch: {
    value(newVal) {
      if (this.withCheckbox && !this.showInput && !!newVal) {
        this.showInput = true;
      }
    },
  },
};
</script>

<style>
.form__error {
  color: #bf2441;
  margin-top: 0;
  width: 80%;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
