<template>
  <div v-loading="loading">
    <el-row justify="space-between">
      <el-col :span="18">
        <div class="n-profile-title">{{ $t("src.components.uicomponents.helper.ressourcestatus.statusListe") }}</div>
      </el-col>
      <!-- NEW STATUS BUTTON -->
      <template v-if="computedEditMode">
        <el-col :span="6" class="text-right" style="padding-right: 20px">
          <el-button type="primary" @click="newStatus" data-testid="new_status">
            <plus-icon />{{ $t("src.components.uicomponents.helper.ressourcestatus.neuerStatus") }}</el-button
          >
        </el-col>
      </template>
    </el-row>

    <div class="card-body">
      <el-row>
        <!-- SEARCH -->
        <el-col :span="5">
          <el-input
            class="n-search"
            v-model="searchQuery"
            :placeholder="$t('src.components.uicomponents.helper.ressourcestatus.sucheNachZeitraumDdmmyyyy')"
            clearable
            suffix-icon="el-icon-search"
          >
          </el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-table :data="statusList" :default-sort="{ prop: 'start', order: 'descending' }">
            <!-- INDEX -->
            <el-table-column type="index" sortable width="60">
              <template v-slot:header>
                <span class="n-table-header">#</span>
              </template>
            </el-table-column>

            <!-- TYPE -->
            <el-table-column min-width="250" sortable prop="type" :filter-method="filterHandler" :filters="typeFilters">
              <template v-slot:header>
                <span class="n-table-header">{{ $t("src.components.uicomponents.helper.ressourcestatus.typ") }}</span>
              </template>
              <template v-slot="scope">
                <document-type-cell
                  :typeOptions="typeOptions"
                  :typeId="scope.row.statusType && scope.row.statusType._id"
                ></document-type-cell>
              </template>
            </el-table-column>

            <!-- DURATION -->
            <el-table-column
              sortable
              :sort-method="sortDateRange"
              prop="start"
              :formatter="formatDateRange"
              min-width="150"
            >
              <!-- HEADER -->
              <template v-slot:header>
                <span class="n-table-header">{{
                  $t("src.components.uicomponents.helper.ressourcestatus.zeitraum")
                }}</span>
              </template>
            </el-table-column>

            <!-- FILE list -->
            <el-table-column min-width="200" sortable prop="document">
              <template v-slot:header>
                <span class="n-table-header">{{ $t("src.components.uicomponents.helper.ressourcestatus.datei") }}</span>
              </template>
              <template v-slot="scope">
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                  <div v-for="(file, idx) in scope.row.fileList" :key="idx">
                    <a href="#" @click.prevent="viewDocument(file)">
                      {{ getFilenameForDocument(file) }}
                    </a>
                  </div>
                </div>
              </template>
            </el-table-column>

            <!-- NOTEs -->
            <el-table-column :show-overflow-tooltip="true">
              <template v-slot:header>
                <span class="n-table-header">{{
                  $t("src.components.uicomponents.helper.ressourcestatus.notizen")
                }}</span>
              </template>
              <template v-slot="scope">
                <div class="notes-icon" v-if="!!scope.row.freeText">
                  <file-document-outline-icon />
                </div>
              </template>
            </el-table-column>

            <!-- ACIONS -->
            <el-table-column fixed="right" width="170">
              <template v-slot="scope">
                <!-- <el-button class="btn btn-sm btn-ghost" @click="viewDocument(scope.row)">
                  <eye-icon />
                </el-button> -->
                <!-- <el-button
                  class="btn btn-sm btn-ghost"
                  @click="downloadDocument(scope.row)"
                  data-testid="status_download"
                  v-if="scope.row.fileList && scope.row.fileList.length"
                >
                  <download-icon />
                </el-button> -->
                <!-- EDIT BUTTON -->
                <el-button
                  class="btn btn-sm btn-ghost"
                  v-if="computedEditMode"
                  @click="handleEditRecord(scope.row, scope.$index)"
                  data-testid="status_edit"
                >
                  <pencil-icon />
                </el-button>
                <!-- View BUTTON -->
                <el-button
                  class="btn btn-sm btn-ghost"
                  @click="handleShowRecord(scope.row, scope.$index)"
                  data-testid="status_view"
                >
                  <eye-outline-icon />
                </el-button>
                <!-- DELETE BUTTON -->
                <el-button
                  class="btn btn-sm btn-ghost"
                  v-if="computedEditMode"
                  @click="handleDeleteRecord(scope.row)"
                  data-testid="status_remove"
                >
                  <trash-can-outline-icon />
                </el-button>
              </template>
            </el-table-column>

            <!-- SLOT EMPTY -->
            <template v-slot:empty>{{
              $t("src.components.uicomponents.helper.ressourcestatus.keinStatusVorhanden")
            }}</template>
          </el-table>
        </el-col>
      </el-row>
    </div>

    <!-- ** DIALOG ** -->
    <el-dialog width="80%" :visible="isEditVisible" @close="closing" data-testid="status_modal" destroy-on-close>
      <!-- HEADER -->
      <div style="padding-left: 20px">
        <span class="n-profile-title-font">
          {{ editingStatus.isNew ? "Neuer Status" : "Status bearbeiten" }}
        </span>
      </div>
      <div class="card-body">
        <el-row style="margin-top: 30px">
          <!-- TYP -->
          <el-col :span="6">
            <profile-select
              :title="$t('src.components.uicomponents.helper.ressourcestatus.typ')"
              :hint="editingStatus.systemRelevant ? 'systemrelevant' : ''"
              :multiple="false"
              :items="typeOptions"
              v-model="editingStatus.selectedTypeID"
              valueIdentifier="_id"
              :label="$t('src.components.uicomponents.helper.ressourcestatus.label')"
              name="Status-Typ"
              customMessage="Bitte Status-Typ auswählen"
              :editMode="!isViewMode"
              required
            />
          </el-col>

          <el-col :span="6" :offset="1">
            <profile-date-picker
              v-model="editingStatus.dateRange"
              is-range
              :title="$t('src.components.uicomponents.helper.ressourcestatus.zeitraum')"
              :name="$t('src.components.uicomponents.helper.ressourcestatus.zeitraum')"
              :isEditMode="!isViewMode"
              required
            />
          </el-col>

          <el-col :span="8" :offset="1" v-if="!editingStatus.noFreeTextAccess">
            <profile-input
              size="default"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              v-model="editingStatus.freeText"
              :label="$t('src.components.uicomponents.helper.ressourcestatus.notizen')"
              :editMode="!isViewMode"
            />
          </el-col>
        </el-row>

        <!-- FILE UPLOAD -->
        <div style="margin-top: 20px" v-if="!editingStatus.noFilesAccess">
          <div>
            <div>
              <span class="n-profile-label-title">{{
                $t("src.components.uicomponents.helper.ressourcestatus.statusAnhang")
              }}</span>
            </div>
            <div>
              <el-upload
                :headers="authHeader"
                :action="actionURI"
                :data="{ metadata: getMetaData(editingStatus) }"
                :on-remove="fileRemoved"
                :multiple="false"
                :on-success="fileUploaded"
                :on-error="handleUploadError"
                :disabled="isViewMode"
                :file-list="editingStatus.fileList"
                data-testid="upload"
                class="drawer-upload"
                :drag="!isViewMode"
              >
                <div v-show="!isViewMode">
                  <div class="upload-icon"><file-upload-outline-icon /></div>
                  <div class="el-upload__text">
                    <b>{{ $t("src.components.project.invoices.drawerupload.whlenSieEineDatei") }}</b
                    >&nbsp;
                    <em style="color: #46a19c">{{
                      $t("src.components.project.invoices.drawerupload.vonIhremComputerAus")
                    }}</em>
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER BUTTONS -->
      <el-row v-if="computedEditMode">
        <div class="n-drawer-footer">
          <cancel-button @click="dismissChanges" data-testid="cancel_status"
            ><span v-if="isViewMode">{{
              $t("src.components.uicomponents.helper.ressourcestatus.schlieen")
            }}</span></cancel-button
          >
          <save-button @click="saveDialog" v-if="!isViewMode" data-testid="save_status" />
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { moment } from "src/config/moment";
import { orderBy } from "lodash";
import { Upload, Message, MessageBox } from "element-ui";
import EyeOutline from "vue-material-design-icons/EyeOutline";
import FileDocumentOutline from "vue-material-design-icons/FileDocumentOutline";
import DocumentTypeCell from "./Documents/DocumentTypeCell";
import DownloadIcon from "vue-material-design-icons/Download";
import EyeIcon from "vue-material-design-icons/Eye";
import FileUploadOutlineIcon from "vue-material-design-icons/FileUploadOutline";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ressource-status",
  components: {
    FileUploadOutlineIcon,
    MessageBox,
    Message,
    [DocumentTypeCell.name]: DocumentTypeCell,
    [Upload.name]: Upload,
    [FileDocumentOutline.name]: FileDocumentOutline,
    [EyeOutline.name]: EyeOutline,
    [DownloadIcon.name]: DownloadIcon,
    [EyeIcon.name]: EyeIcon,
  },
  props: {
    accessRights: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
    statusModel: {
      type: String,
      default: "",
      required: true,
    },
    statusModelID: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      typeOptions: [], //types are loaded from settings
      isViewMode: false,
      isEditVisible: false,
      editingIdx: false,
      actionURI: this.axios.defaults.baseURL + "/api/fileupload",
      searchQuery: "",
    };
  },
  watch: {
    statusModelID: function (newVal, oldVal) {
      if (newVal) {
        this.loading = false;
      }
      //statusModelID comes in later when data is loaded from Edit.vue
      if (newVal && oldVal !== newVal) {
        try {
          this.$store.dispatch("ressourceStatus/initAsync", {
            statusModelID: this.statusModelID,
            statusModel: this.statusModel,
          });
        } catch (error) {
          Message({
            message: error.message,
            type: "error",
          });
        }

        // now we can stop to show the loading spinner
      }
    },
  },
  computed: {
    ...mapGetters("account", ["authHeader"]), //for file upload
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
    statusList: {
      get: function () {
        let dataList = this.$store.getters["ressourceStatus/statusList"];
        let filteredDataList = this.filter(dataList, this.searchQuery);
        return filteredDataList;
      },
    },
    editingStatus: {
      get: function () {
        return this.$store.getters["ressourceStatus/editingStatus"];
      },
      set: function (newValue) {
        this.$store.commit("ressourceStatus/updateEditingStatus", newValue);
      },
    },
    typeFilters() {
      // here we have _id :(
      return this.typeOptions.map((item) => ({ text: item.label, value: item._id }));
    },
  },
  mounted() {
    //start loading animation -> we have to wait till statusModelID comes in (see watcher
    if (!this.statusModelID) {
      this.loading = true;
    }

    this.$root.$on("settingsChanged", (data) => {
      //we are just interested in the document settings here
      if (data.modelType === "status") {
        this.typeOptions = orderBy(data.options, ["label"], ["asc"]);
        console.log("settingsChanged", this.typeOptions);
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("settingsChanged");
  },
  methods: {
    ...mapActions("pdfViewer", { openPdf: "open" }),
    viewDocument(record) {
      const url = this.getUrlForDocument(record);
      if (url.toLowerCase().endsWith(".pdf")) {
        const completeUrl = this.axios.defaults.baseURL + report.url;
        this.openPdf({ url: completeUrl, fileName: record.fileList[0].name });
      } else {
        window.open(`${url}?view=true`, "_blank");
      }
    },
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    filter(dataList, searchQuery) {
      let testFormat = searchQuery.split(".");
      // when there are not 2 dots and 3 values
      if (testFormat.length !== 3) {
        return dataList;
      }

      // try to guess the date
      let searchDate = moment(searchQuery, ["DD.MM.YYYY", "D.M.YY"]);
      if (searchDate != null && searchDate.isValid()) {
        let filteredList = dataList.filter((data) => {
          let start = moment(data.start);
          let end = moment(data.end);
          let withInRange = searchDate.isBetween(start, end, "days", true); // will return true
          return withInRange;
        });

        console.log(filteredList);
        return filteredList;
      }

      return dataList;
    },
    sortDateRange(a, b) {
      const aDate = new Date(a.start).getTime();
      const bDate = new Date(b.start).getTime();
      if (aDate < bDate) {
        return -1;
      } else if (bDate < aDate) {
        return 1;
      } else {
        return 0;
      }
    },
    formatDateRange(datarow) {
      if (!datarow.start || !datarow.end) {
        return " - ";
      }
      return `${moment(datarow.start).format("L")} - ${moment(datarow.end).format("L")}`;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    getFilenameForDocument(record) {
      // return record.fileList && record.fileList[0] ? record.fileList[0].name : undefined;
      return (record && record.name) || undefined;
    },
    getUrlForDocument(record) {
      return record.fileList && record.fileList[0] ? record.fileList[0].url : "";
    },
    downloadDocument(record) {
      // const url = this.getUrlForDocument(record);
      const url = record && record.url;
      window.open(url, "_blank");
    },
    handleShowRecord(storedRecord, index) {
      this.isEditVisible = true;
      this.isViewMode = true;
      this.editingIdx = index;
      this.editingStatus = {
        id: storedRecord.id,
        dateRange: [storedRecord.start || new Date(), storedRecord.end || new Date()],
        fileList: storedRecord.fileList || [],
        selectedTypeID: storedRecord.statusType._id, //here we have _id
        systemRelevant: storedRecord.statusType.systemRelevant || false,
        freeText: storedRecord.freeText || "",
        resourceType: storedRecord.resourceType,
        resourceId: storedRecord.resourceId,
        noFreeTextAccess: storedRecord.noFreeTextAccess,
        noFilesAccess: storedRecord.noFilesAccess,
      };
    },
    handleEditRecord(storedRecord, index) {
      this.isEditVisible = true;
      this.editingIdx = index;
      this.editingStatus = {
        id: storedRecord.id,
        dateRange: [storedRecord.start || new Date(), storedRecord.end || new Date()],
        fileList: storedRecord.fileList || [],
        selectedTypeID: storedRecord.statusType._id, //here we have _id
        systemRelevant: storedRecord.statusType.systemRelevant || false,
        freeText: storedRecord.freeText || "",
        resourceType: storedRecord.resourceType,
        resourceId: storedRecord.resourceId,
        noFreeTextAccess: storedRecord.noFreeTextAccess,
        noFilesAccess: storedRecord.noFilesAccess,
      };
    },
    closing() {
      this.dismissChanges();
    },
    async dismissChanges() {
      //'isNew' will be added manually when new status is created. Its not part of database structure
      if (this.editingStatus.isNew) {
        try {
          console.log("dismisschanges", this.editingStatus);
          await this.$store.dispatch("ressourceStatus/delete", this.editingStatus.id);
        } catch (error) {
          Message({ message: error.message, type: "error" });
        }
      }
      this.isViewMode = false;
      this.isEditVisible = false;
      this.editingStatus = {};
    },
    async handleDeleteRecord(status) {
      MessageBox.confirm("wirklich löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      })
        .then(async () => {
          try {
            let fileName = null;
            if (status.fileList.length > 0) {
              fileName = status.fileList[0].name;
            }
            await this.$store.dispatch("ressourceStatus/delete", status.id);
            if (fileName) {
              await this.deleteFile(fileName);
            }
            Message({ message: "Status gelöscht", type: "success" });
          } catch (error) {
            Message({ message: error.message, type: "error" });
          }
        })
        .catch(() => {
          // do nothing
        });
    },
    async saveDialog() {
      try {
        if (!this.editingStatus.dateRange || this.editingStatus.dateRange.length == 0) {
          MessageBox.alert("Bitte Zeitraum eintragen", "", {
            type: "error",
            showClose: false,
          });
          return;
        }
        if (!this.editingStatus.selectedTypeID) {
          MessageBox.alert("Bitte Status-Typ auswählen", "", {
            type: "error",
            showClose: false,
          });
          return;
        }

        await this.save();

        this.dismissChanges();
      } catch (error) {
        throw error;
      }
    },
    async save() {
      // get status type from settings. Here we have _id
      let statusType = this.typeOptions.find((item) => item._id === this.editingStatus.selectedTypeID) || {};

      const payload = {
        id: this.editingStatus.id,
        start: moment(this.editingStatus.dateRange[0]).format("YYYY-MM-DD"),
        end: moment(this.editingStatus.dateRange[1]).format("YYYY-MM-DD"),
        statusType: statusType._id,
        fileList: this.editingStatus.fileList,
        resourceType: this.editingStatus.resourceType,
        resourceId: this.editingStatus.resourceId,
        freeText: this.editingStatus.freeText,
      };
      try {
        await this.$store.dispatch("ressourceStatus/update", payload);
        Message({ message: "Status gespeichert", type: "success" });
      } catch (error) {
        // if resource is busy with other event withing specified daterange
        if (error.response && error.response.data && error.response.data.code === 10) {
          Message({ message: "Die Ressource ist innerhalb des angegebenen Datumsbereichs nicht frei", type: "error" });
        } else {
          Message({ message: error.message, type: "error" });
        }
        throw error;
      }
    },
    getMetaData(status) {
      const metadata = this.statusModel + "_" + this.statusModelID + "_status_" + status.id;
      return metadata;
    },
    async fileRemoved(file) {
      let restoreFileList = [...this.editingStatus.fileList];
      try {
        await this.deleteFile(file.name);

        this.editingStatus.fileList = this.editingStatus.fileList.filter((item) => item.name !== file.name);
        await this.saveFileList(this.editingStatus.fileList);
        Message({ message: "Datei aus Status entfernt", type: "success" });
      } catch (error) {
        this.editingStatus.fileList = restoreFileList;
        Message({ message: error.message, type: "error" });
      }
    },
    async deleteFile(fileName) {
      const metadata = this.getMetaData(this.editingStatus);
      this.axios.delete("/api/fileupload", { params: { filename: fileName, metadata: metadata } });
    },
    async fileUploaded(response) {
      if (response.length === 1 && this.editingStatus) {
        let restoreFileList = [...this.editingStatus.fileList];

        this.editingStatus.fileList.push({
          name: response[0].name,
          url: response[0].url,
        });

        //HACK: save just the file list or we have inconsistent data when user cancels withoput saving!
        try {
          await this.saveFileList(this.editingStatus.fileList);
          Message({ message: "Datei zu Status hinzugefügt", type: "success" });
        } catch (error) {
          this.editingStatus.fileList = restoreFileList;
          Message({ message: error.message, type: "error" });
        }
      }
    },
    async saveFileList(fileList) {
      let { id } = this.editingStatus;
      let ressourceStatus = { id: id, fileList: fileList, isNew: this.editingStatus.isNew };

      await this.$store.dispatch("ressourceStatus/updateFileList", ressourceStatus);
    },
    async newStatus() {
      try {
        // store sets EditingStatus when data is created -> good thing is that id is then included
        await this.$store.dispatch("ressourceStatus/createEmpty");
        this.isEditVisible = true;
      } catch (error) {
        Message({ message: error.message, type: "error" });
      }
    },
  },
};
</script>
