<template>
  <el-dialog :visible="visible" @close="handleClose" :title="$t('Edit units')" width="400px">
    <div class="flex justify-between align-items-center">
      <el-input-number v-model="quantity" />
      <span>{{ unitName }}</span>
      <el-button type="primary" @click.prevent="handleSave" :loading="loading">{{ $t("Save") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, InputNumber, Form, FormItem, Message } from "element-ui";

export default {
  name: "event-quantity-dialog",
  props: {
    visible: { type: Boolean, required: true },
    resourceRecord: { type: Object },
    eventRecord: { validator: (prop) => typeof prop === "object" || prop === null, required: true },
    onSave: { type: Function, required: true },
  },
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [InputNumber.name]: InputNumber,
  },
  data() {
    return {
      loading: false,
      quantity: null,
    };
  },
  methods: {
    handleClose() {
      this.quantity = null;
      this.$emit("close");
    },
    async handleSave() {
      try {
        const quantityNumber = parseInt(this.quantity);
        if (isNaN(quantityNumber)) {
          throw new Error("Quantity should be a number");
        }
        this.loading = true;
        await this.onSave(this.eventRecord.dbEventId, quantityNumber);
        this.handleClose();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (!oldVal && newVal) {
        console.log("this.eventRecord", this.eventRecord);
        this.quantity = this.eventRecord.quantity || 0;
      }
    },
  },
  computed: {
    unitName() {
      if (this.resourceRecord && this.resourceRecord.unitName) {
        return this.resourceRecord.unitName;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
